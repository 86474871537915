@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  font-family: "Roboto", sans-serif !important;
}

.App{
  height: 100vh;
}

.fixed-header{
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffffff;
  width: 100% !important;
}

.left-arrrow {
  margin: auto;
  background: #d5d5d9;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.option {
  margin: auto;
  background: #d5d5d9;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
}

.header-img {
  height: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.logo-img {
  height: 100%;
  width: 100%;
}

.message-box {
  background: #efefef;
  border: 1px solid #d5d5d9;
  text-align: left;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  cursor: pointer;
 
}

.all-messages{
  max-height: 300px;
  overflow: scroll;
}

.message{
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  text-align: left;
  width: auto;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.box {
  border: 1px solid #d5d5d9;
  text-align: left;
  width: auto;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
}

.box a {
  color: black;
}

.fa-arrow-right {
  cursor: pointer;
  padding: 10px;
}

.availability-btn {
  background-color: rgba(103, 80, 164, 1) !important;
  color: #ffffff !important;
  border-radius: 15px !important;
}

.btn-discard {
  background-color: #eef1f4 !important;
  color: #202023 !important;
  width: 100% !important;
}

.btn-save {
  background-color: rgba(119, 217, 103, 1) !important;
  color: #ffffff !important;
  width: 100% !important;
}

.bill-submit{
    width: auto !important;
}

.cross-buttons {
  position: relative;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  width: 100%;
}

.cross-button-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
}

.cross-button {
  position: relative;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}


.sigCanvas {
  border: 1px solid #dee2e6;
  width: 75%;
  height: 200px;
}


.sigCanvasFull {
  border: 1px solid #dee2e6;
  width: 100%;
  height: 200px;
}

@media screen and (min-width: 920px) {
  .custom-container {
    max-width: 50vw !important;
    padding-bottom: 5px;
  }
}


@media screen and (max-width: 991px) {
  .sigCanvas {
    width: 100%;
  }

  
}
#header-option{
  display: none;
}

@media screen and (max-width: 500px) {
 #header-option{
  display: block;
 }

 #logout-btn{
  display: none;
 }
  
}





#dropdown-basic::after{
  display: none !important;
}

.linkbox{
  text-decoration: none !important;
  color: inherit !important;
}

.hr-container {
  text-align: center;
  position: relative;
}

.hr-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white; 
  padding: 0 10px; 
}

/* Quiz */

.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.question-heading {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: left;
}

.quizContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 20px;
  width: 55vw;
}

.quizSignContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 20px;
  width: 55vw;
}

.signature-quiz-pad {
  width: 80%;
}

.optionsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-quiz {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .quizSignContainer {
    width: 90vw;
  }

  .signature-quiz-pad {
    width: 100%;
  }

  .quizContainer {
    width: 90vw;
  }

  .sd-root-modern--mobile,
  .sd-body,
  .sd-body--responsive,
  .sd-root-modern--mobile,
  .sd-body.sd-body--static {
    padding: 0 !important;
  }

  .sd-action-bar {
    display: none !important ;
  }
}

.contactBoxStyle {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.contact-section {
  width: 50%;
}

.fa-reset-sign {
  background: red;
  border-radius: 50%;
  color: white;
  padding: 2px;
  margin: 5px;
  cursor: pointer;
}

.preview-signature {
  background: #f3f3f3;
  justify-content: center;
  align-items: center;
  padding: 15px 0 20px 0;
}

.preview-signature-policy {
  background: #f3f3f3;
  justify-content: center;
  align-items: center;
  padding: 20px 0 20px 0;
}

/* Hide signature button */
#sv-nav-complete {
  display: none;
}

/* sd-action-bar  sd-body__navigation sd-clearfix */
.sd-action-bar,
.sd-footer {
  display: none !important ;
}

.css-wjh20t-MuiPagination-ul {
  justify-content: end !important;
}

.signature-erase {
  color: green;
  right: 56px;
  margin-top: 10px;
  background: white;
  border: none;
  cursor: pointer;
}

.signature-erase-policy{
  right: 14%;
}

.signature-erase-consent{
  right: 110px !important;
}

.signature-erase-billable{
  right: 110px !important;
}

.signature-box{
  display: flex ;
  flex-direction: row ;
}

.signatureCanvas{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
}


@media screen and (max-width: 770px) {
  .signature-box{
    flex-direction: column !important;
  }

  .signature-erase{
    right: 30px;
  }

  .signature-erase-consent{
    right: 20px !important;
    top: 10px;
  }

  .signature-erase-policy{
    right: 18%;
  }

  .signature-erase-billable{
    right: 80px !important;
  }


}

/* Custom CSS for smooth transition */
.accordion-collapse {
  transition: height 0.1s ease, opacity 0.1s ease;
  overflow: hidden;
}

.accordion-collapse {
  opacity: 0;
  height: 0;
}

.accordion-collapse.show {
  opacity: 1;
  height: auto; /* Allow height to auto expand */
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: #D5D5D9; /* Background color */
  cursor: pointer;
  font-size: 17px; /* Adjust icon size */
  color: #333; /* Icon color */
}